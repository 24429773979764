export const environment = {
  production: true,
  staging: false,
  agora: false,
  dev: false,
  isSandbox: false,
  CDNBaseUrl: 'https://d2vqi2v0qh47aa.cloudfront.net',
  api_url: 'https://api.agora-victoria.com',
  identity_url: 'https://connect.victoria-company.com/',
  sockets_url: 'https://api.agora-victoria.com',
  base_url_benelux: 'https://victoria-benelux.com',
  base_url_france: 'https://victoria-bijoux.fr',
  base_url_allemagne: 'https://victoria-schmuck.com',
  tenantId: '6e338ab9-a77c-815c-97a4-47c169fdab8f',

  cookie_domain: 'agora-victoria.com',
  cookie_domain_benelux: 'victoria-benelux.com',
  cookie_domain_france: 'victoria-bijoux.fr',
  cookie_domain_allemagne: 'victoria-schmuck.com',

  base_url: '',
  eshop_url_benelux: 'https://my.victoria-benelux.com',
  eshop_url_france: 'https://my.victoria-bijoux.fr',
  eshop_url_allemagne: 'https://my.victoria-schmuck.com',

  datadog_client_token: 'pub9e29e1f9b447d1737c8129b9b9bb348b',
  datadog_env: 'prod',
  datadog_active: true,

  version: '2025.1.0',
  showVersion: false,

  allowedLocales: { fr: ['fr-fr'], be: ['nl-be', 'fr-be'], de: ['de-de'] },
  supportedLocales: { fr: ['fr-fr'], be: ['nl-be', 'fr-be'], de: ['de-de'] },
  instrumentationKey: 'fe3e7735-fef5-47fe-aff7-e4ca5712ad45',
  intranetUrlByContext: { 1: 'https://{env}.victoria-france.fr', 2: 'https://{env}.victoria-benelux.be', 3: 'https://{env}.victoria-deutschland.de' },
};
